@font-face {
  font-family: "Scope-BoldExpanded";
  src: url("Fonts/Scope-BoldExpanded.woff2") format("woff2"),
    url("Fonts/Scope-BoldExpanded.woff") format("woff");
}

@font-face {
  font-family: "Scope-LightExpanded";
  src: url("Fonts/Scope-LightExpanded.woff2") format("woff2"),
    url("Fonts/Scope-LightExpanded.woff") format("woff");
}

@font-face {
  font-family: "Scope-MediumExpanded";
  src: url("Fonts/Scope-MediumExpanded.woff2") format("woff2"),
    url("Fonts/Scope-MediumExpanded.woff") format("woff");
}

:root {
  --content-width: 100%;

  /* Day */
  --color-day: hsl(0, 0%, 5%);
  --second-day:  hsl(248, 88%, 57%);
  --background-day: hsl(248, 0%, 98%);

  /* Night */
  --color-night: var(--background-day);
  --second-night: var(--second-day);
  --background-night: var(--color-day);

  --color: var(--color-day);
  --second: var(--second-day);
  --background: var(--background-day);
  --yellow: hsl(59, 100%, 61%);
  --green: hsl(135, 100%, 61%);
  --grey: hsl(248, 10%, 95%);
  --light: hsl(255,100%,100%);
  --dark-grey: hsl(0, 0%, 57%);
  --red: #e02500;

  --font-light: "Scope-LightExpanded", "Courir", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --font-bold: "Scope-MediumExpanded", "Courir", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  --font-medium: "Scope-MediumExpanded", "Courir", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}



html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: var(--font-light);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.25;
  font-size: 1.5rem;
  color: var(--color);
  background: var(--background);
}

a {
  color: var(--color);
  text-decoration: none;
  text-transform: uppercase;
}

a:hover {
  color: var(--second);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-bold);
  font-weight: 400;
  font-size: 2rem;
  text-transform: uppercase;
}

h4,
h5,
h6 {
  font-size: 1.5rem;
}

h1 {
  margin-top: 0;
  margin-bottom: 4rem;
}

h2 {
  margin-top: 0;
  margin-bottom: 0rem;
}

h3 {
  margin-top: 0;
  margin-bottom: 4rem;
}

h4 {
  margin-bottom: 0rem;
    margin-top: 4rem;
}

h5 {
  margin-bottom: 0rem;
    margin-top: 4rem;
}

h6 {
  margin-top: 0;
  margin-bottom: 0rem;
    margin-top: 4rem;
}

b,
strong {
  font-family: "Scope-BoldExpanded", "Courir", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin-bottom: 2rem;
}
@media (max-width: 769px) {
  p {
    font-size: 1rem;
  }
}
img {
  display: block;
}
button {
  font-family: var(--font-light);
  font-size: 1rem;
  background: none;
  border: none;
  color: var(--color);
  cursor: pointer;
  margin: 0;
  padding: 0;
  display: inline;
  text-align: left;
  border-radius: .1rem;
}
button:hover {
  color: var(--second);
}
button:focus {
  outline: var(--second);
}

input {
  border:none;
  outline: none;
  font-family: var(--font-medium);
  font-size: 1.25rem;
  background: none;
  line-height: 2rem;
  padding: 0 .5rem;
  border-radius: .1rem;
  color:var(--color);
  background: var(--background);
}
input[type=range] {
  padding: 0;
}
input:hover {
    color: var(--second);
}
input:focus, input:focus-visible {
    border:none;
    outline: none;
}


.rotate {
  transform: rotateY(45deg);
  animation: rotateAnimation 3s linear infinite;
}

@keyframes rotateAnimation {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(359deg);
  }
}